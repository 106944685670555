import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import { LanguageProvider } from './LanguageContext';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import ProblemPostPage from './pages/ProblemPostPage';
import Header from './components/Header';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardPage from './pages/DashboardPage'; // Correctly imported here
import ProtectedRoute from './components/ProtectedRoute';
import { UserProvider } from './contexts/UserContext';
import axios from 'axios';


function App() {
  return (
    <UserProvider>
      <LanguageProvider>
          <Router>
              <Header />
              <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/register" element={<RegisterPage />} />
                  <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} /> {/* Corrected here */}
                  {/* ...other routes... */}
              </Routes>
              <Footer />
          </Router>
      </LanguageProvider>
    </UserProvider>
  );
}

export default App;
