// components/Header.js
import React from 'react';

function Header() {
  return (
    <header>
      {/* Navigation bar content */}
    </header>
  );
}

export default Header;