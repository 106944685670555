import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    try {
        const token = localStorage.getItem('userToken');
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            console.log('Sending request with token:', token); // Log the token being used
            
            const response = await axios.get('https://haus-decorationen.eu:5000/auth/me');
            console.log('Response received:', response.data); // Log the response

            if (response.data && response.data.user) {
                setUser(response.data.user); // Set user data in context
                console.log('User data set in context:', response.data.user);
            }
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
        console.log(error.response); // Log the error response
    }
};

  useEffect(() => {
    

    fetchUser();
}, []);


  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('userToken', userData.token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;
    fetchUser();
};

  const logout = () => {
    localStorage.removeItem('userToken');
    setUser(null);
    axios.defaults.headers.common['Authorization'] = ''; // Remove the default Authorization header
    // Redirect to login or home page
    
};
 
  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);