import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../LanguageContext';
import './RegisterPage.css';
import { Link } from 'react-router-dom';


function RegisterPage() {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    password: '',
    userType: 'individual',
    country: 'Austria',
    zipCode: '',
    street: '',
    houseNumber: '',
    phoneNumber: ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);

  const translations = {
    en: {
      fullName: 'Full Name/Company Name', // Translation for full name
      register: 'Register',
      email: 'Email',
      password: 'Password',
      individual: 'Individual',
      company: 'Company',
      country: 'Austria',
      zipCode: 'Zip Code',
      street: 'Street',
      houseNumber: 'House Number',
      phoneNumber: 'Phone Number',
      submit: 'Submit',
      welcome: 'Register for Home Contractor App',
      description: 'Create an account to start connecting with local service providers.',
      success: 'Registration successful! You can now login.',
      success: 'Registrierung erfolgreich! Sie können sich jetzt anmelden.',
      error: {
        // Add all the error messages you expect from the backend
        'Invalid email': 'Invalid email',
        'Password must be at least 6 characters long': 'Password must be at least 6 characters long',
        'Email already in use': 'Email already in use',
        'Invalid street': 'Invalid street',
        'Invalid zip code': 'Invalid zip code',
        'Invalid house number': 'Invalid house number',
        'Invalid phone number': 'Invalid phone number',
        'Invalid full name': 'Invalid full name/company name', // Error for invalid full name
        // ... more error messages
      }
    },
    de: {
      fullName: 'Vollständiger Name/Firmenname', // Translation for full name
      register: 'Registrieren',
      email: 'E-Mail',
      password: 'Passwort',
      individual: 'Privatperson',
      company: 'Unternehmen',
      country: 'Österreich',
      zipCode: 'Postleitzahl',
      street: 'Straße',
      houseNumber: 'Hausnummer',
      phoneNumber: 'Telefonnummer',
      submit: 'Registrieren',
      welcome: 'Registrieren für die Home Contractor App',
      description: 'Erstellen Sie ein Konto, um die Verbindung zu lokalen Dienstleistern zu beginnen.',
      error: {
        // Translated error messages
        'Invalid email': 'Ungültige E-Mail',
        'Password must be at least 6 characters long': 'Das Passwort muss mindestens 6 Zeichen lang sein',
        'Email already in use': 'E-Mail bereits verwendet',
        'Invalid street': 'Ungültige Straße',
        'Invalid zip code': 'Ungültige Postleitzahl',
        'Invalid house number': 'Ungültige Hausnummer',
        'Invalid house number': 'Ungültige Telefonnummer',
        'Invalid full name': 'Ungültiger vollständiger Name/Firmenname', // Error for invalid full name
        // ... more error messages
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessages([]);
  
    try {
      const response = await fetch('https://haus-decorationen.eu:5000/auth/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
  
      if (response.ok) {
        const data = await response.json();
        setSuccessMessage(translations[language].success); // Set the success message
        // Redirect to login page after a short delay to show success message
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        const errorData = await response.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          const translatedErrors = errorData.errors.map(err => translations[language].error[err.msg] || err.msg);
          setErrorMessages(translatedErrors);
        } else {
          setErrorMessages([translations[language].error[errorData.error] || errorData.error || "An unknown error occurred"]);
        }
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      setErrorMessages(["Failed to send request. Please try again."]);
    }
  };

  const t = (key) => translations[language][key] || key; // Fallback to key if translation not found

  return (
    <div className="home-page-bg">
      <div className="container text-center">
        <div className="register-card">
          <div className="logo-container">
          <Link to="/">
    <img src="/logonobg.png" alt="Home Contractor Logo" className="logo" />
</Link>
          </div>
          <h1>{t('welcome')}</h1>
          <p className="description">{t('description')}</p>
          <form onSubmit={handleSubmit} className="register-form">
            <h2>{t('register')}</h2>
            <input type="text" name="fullName" value={formData.fullName} onChange={handleInputChange} placeholder={t('fullName')} />
            <input type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder={t('email')} />
            <input type="password" name="password" value={formData.password} onChange={handleInputChange} placeholder={t('password')} />
            <select name="userType" value={formData.userType} onChange={handleInputChange}>
              <option value="individual">{t('individual')}</option>
              <option value="company">{t('company')}</option>
            </select>
            <select name="country" value={formData.country} onChange={handleInputChange}>
              <option value="Austria">{t('country')}</option>
              {/* Add other countries as needed */}
            </select>
            <input type="text" name="zipCode" value={formData.zipCode} onChange={handleInputChange} placeholder={t('zipCode')} />
            <input type="text" name="street" value={formData.street} onChange={handleInputChange} placeholder={t('street')} />
            <input type="text" name="houseNumber" value={formData.houseNumber} onChange={handleInputChange} placeholder={t('houseNumber')} />
            <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} placeholder={t('phoneNumber')} />
            <button type="submit">{t('submit')}</button>
          </form>
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessages.length > 0 && <div className="alert alert-danger">{errorMessages.map((message, index) => <p key={index}>{message}</p>)}</div>}
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
