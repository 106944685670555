import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../LanguageContext';
import './LoginPage.css';
import { Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

function LoginPage() {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errorMessages, setErrorMessages] = useState([]);

  const translations = {
    en: {
      login: 'Login',
      email: 'Email',
      password: 'Password',
      submit: 'Submit',
      error: 'Invalid email or password',
      welcome: 'Login to Home Contractor App',
      description: 'Access your account to connect with local service providers.',
      success: 'Logged in successfully',
      errors: {
        'Invalid email': 'Invalid email',
        'Password is required': 'Password is required',
        'Invalid email or password': 'Invalid email or password',
        'Internal Server Error': 'An internal server error occurred',
        // ... other error messages ...
      }
    },
    de: {
      login: 'Anmelden',
      email: 'E-Mail',
      password: 'Passwort',
      submit: 'Einreichen',
      error: 'Ungültige E-Mail oder Passwort',
      welcome: 'Anmelden bei der Home Contractor App',
      description: 'Zugriff auf Ihr Konto, um sich mit lokalen Dienstleistern zu verbinden.',
      success: 'Erfolgreich angemeldet',
      errors: {
        'Invalid email': 'Ungültige E-Mail',
        'Password is required': 'Passwort wird benötigt',
        'Invalid email or password': 'Ungültige E-Mail oder Passwort',
        'Internal Server Error': 'Interner Serverfehler',
        // ... other error messages ...
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
};

const { login } = useUser();

const handleSubmit = async (event) => {
  event.preventDefault();
  setErrorMessages([]);

  try {
    const response = await fetch('https://haus-decorationen.eu:5000/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    });

    if (response.ok) {
      const data = await response.json();
      console.log('Login successful:', data); // Debug log
      login(data); // Update the user context with the logged-in user data
      navigate('/dashboard'); // Redirect to the dashboard
    } else {
      const errorData = await response.json();
      setErrorMessages([translations[language].error[errorData.error] || errorData.error]);
    }
  } catch (error) {
    console.error('Login request failed:', error);
    setErrorMessages(["Failed to send request. Please try again."]);
    
  }
};

const t = (key) => translations[language][key] || key; // Fallback to key if translation not found


  return (
    <div className="login-page-bg">
      <div className="login-page text-center">
        <div className="card my-5 py-4">
        <div className="logo-container">
          <Link to="/">
    <img src="/logonobg.png" alt="Home Contractor Logo" className="logo" />
</Link>
<h1>{t('welcome')}</h1>
          <p className="description">{t('description')}</p>
          </div>
          <h2>{t('login')}</h2>
          <form onSubmit={handleSubmit} className="login-form">
            <input type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder={t('email')} required />
            <input type="password" name="password" value={formData.password} onChange={handleInputChange} placeholder={t('password')} required />
            <button type="submit" className="btn btn-primary">{t('submit')}</button>
          </form>
          {errorMessages.length > 0 && (
            <div className="alert alert-danger">
              {errorMessages.map((message, index) => (
                <p key={index}>{message}</p>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
