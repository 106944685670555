// src/components/Dashboard.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import './DashboardPage.css';
import AdComponent from '../components/AdComponent'; // Import the AdComponent

function Dashboard() {
  const { user, logout } = useUser();
  console.log('User data in Dashboard:', user);
  const topRatedCompanies = [
    { name: 'Company A', rating: 5 },
    { name: 'Company B', rating: 4.5 },
    // ... other companies
  ];
  function renderStarRating(rating) {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<span key={i} className="full-star">★</span>); // Full star
      } else if (i === Math.floor(rating) && rating % 1 >= 0.5) {
        stars.push(<span key={i} className="half-star">☆</span>); // Half star
      } else {
        stars.push(<span key={i} className="empty-star">☆</span>); // Empty star
      }
    }
    return <span className="star-rating">{stars}</span>;
  }

  return (
    <div className="dashboard-bg">
      <div className="dashboard-card">
        <div className="d-flex justify-content-between">
        <h3 className="welcome-message">Welcome to the Home Constructor,</h3> <h2 className="welcome-message1">{user?.fullName}</h2>
        <button onClick={logout} className="btn btn-danger logout-btn">
  <i className="fas fa-sign-out-alt"></i> Logout
</button>
        </div>

        <div className="navigation-buttons text-center">
          <Link to="/dashboard" className="nav-btn btn btn-primary">Home</Link>
          <Link to="/myaccount" className="nav-btn btn btn-primary">My Account</Link>
          {user?.userType === 'individual' ? (
            <Link to="/myproblems" className="nav-btn btn btn-primary">My Problems</Link>
          ) : (
            <Link to="/userproblems" className="nav-btn btn btn-primary">User Problems</Link>
          )}
          <Link to="/company" className="nav-btn btn btn-primary">Company</Link>
        </div>

        <div className="row mt-4">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body text-center">
                <h5 className="card-title">Top Rated Companies</h5>
                {topRatedCompanies.map(company => (
  <p key={company.name}>
    {company.name} - Rating: {renderStarRating(company.rating)}
  </p>
))}
              </div>
            </div>
          </div>

          <div className="col-md-6 text-center">
            {user?.userType === 'individual' ? (
              <Link to="/addproblem" className="btn btn-success"><i className="fa-solid fa-plus"></i>  Add New Problem</Link>
            ) : (
              <Link to="/fixproblems" className="btn btn-warning"><i className="fa-solid fa-screwdriver-wrench fa-bounce"></i>  Fix Problems</Link>
            )}
          </div>
        </div>

        <div className="ad-section text-center">
  <p>Advertisement</p>
  <div className="ad-content">
  <AdComponent /> 
  </div>
</div>
      
      </div>
    </div>
  );
}

export default Dashboard;

