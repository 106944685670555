import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageContext';
import { Link } from 'react-router-dom';
import './HomePage.css';


function HomePage() {
  const { language, setLanguage } = useContext(LanguageContext);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const getTranslation = (key) => {
    const translations = {
      en: {
        welcome: 'Welcome to the Home Contractor App',
        description: 'The first platform of its kind to efficiently connect you with local service providers.',
        login: 'Login',
        register: 'Register',
        explore: 'Explore as Guest',
        language: 'Select Language:'
      },
      de: {
        welcome: 'Willkommen bei der Home Contractor App',
        description: 'Die erste Plattform ihrer Art, die Sie effizient mit lokalen Dienstleistern verbindet.',
        login: 'Anmelden',
        register: 'Registrieren',
        explore: 'Als Gast erkunden',
        language: 'Sprache Auswählen:'
      }
      // Add other languages as needed
    };

    return translations[language][key];
  };

  return (
    <div className="home-page-bg">
      
        <div className="home-page-card">
        <div className="text-center">
        <div className="logo-container">
          <Link to="/">
    <img src="/logonobg.png" alt="Home Contractor Logo" className="logo" />
</Link>
          </div>
  </div>

          <h1 className="mb-3">{getTranslation('welcome')}</h1>
          <p className="lead mb-4">{getTranslation('description')}</p>
          
          <div className="button-container">
            <Link to="/login" className="btn btn-lg btn-outline-primary mb-2">{getTranslation('login')}</Link>
            <Link to="/register" className="btn btn-lg btn-outline-secondary mb-2">{getTranslation('register')}</Link>
            <Link to="/explore" className="btn btn-lg btn-outline-info">{getTranslation('explore')}</Link>
            <div className="language-text">{getTranslation('language')}</div>
            <div className="language-selector">
            <select value={language} onChange={handleLanguageChange} className="form-select">
              <option value="en">English</option>
              <option value="de">Deutsch</option>
              {/* Add other languages as needed */}
            </select>
          </div>
          </div>
        </div>
      </div>
    
  );
}

export default HomePage;